<template>
  <my-simple-layout>
    <template>
      404
    </template>
  </my-simple-layout>
</template>

<script>
export default {
  components: {
    "my-simple-layout": () => import("@/components/SimpleLayout"),
  },
}
</script>

<style>

</style>